import { createStore as reduxCreateStore } from 'redux';

const reducer = (state, action) => {
	if (action.type === `INCREMENT`) {
		return Object.assign({}, state, {
			count: state.count + 1
		});
	}
	if (action.type === 'TOGGLE_SIDENAV') {
		return Object.assign({}, state, {
			isSideNavOpen: !state.isSideNavOpen
		});
	}
	return state;
};

const initialState = { count: 0 };

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;
